export default [
  {
    id: "anxiety",
    title: "Anxiety disorders",
  },
  {
    id: "depression",
    title: "Depression",
  },
  {
    id: "bipolarAndRelated",
    title: "Bipolar and related disorders",
  },
  {
    id: "adhd",
    title: "ADHD",
  },
  {
    id: "eating",
    title: "Eating disorders",
  },
  {
    id: "personality",
    title: "Personality disorders",
  },
  {
    id: "autismSpectrum",
    title: "Autism spectrum disorders",
  },
  {
    id: "seflDoubt",
    title: "Improving self-esteem",
  },
  {
    id: "stress",
    title: "Dealing with stress",
  },
  {
    id: "trauma",
    title: "Dealing with past trauma",
  },
  {
    id: "sexuality",
    title: "Sexuality",
  },
  {
    id: "grief",
    title: "Dealing with grief",
  },
]

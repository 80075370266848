<script setup>
  import { onMounted, computed, watch } from 'vue'
  import { useForm, configure } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as z from 'zod'
  import { FormField } from '../shadcn-vue/form'
  import AleaButton from './AleaButton.vue'
  import AleaInput from './AleaInput.vue'
  import AleaTextarea from './AleaTextarea.vue'
  import AleaSelect from './AleaSelect.vue'
  import AleaMultiselect from './AleaMultiselect.vue'
  import AleaSwitch from './AleaSwitch.vue'
  import AleaLabel from './AleaLabel.vue'
  import AleaRadioGroup from './AleaRadioGroup.vue'
  import AleaDatepicker from './AleaDatepicker.vue'
  import AleaCheckbox from './AleaCheckbox.vue'
  import AleaFile from './AleaFile.vue'
  import AleaToggleGroup from './AleaToggleGroup.vue'
  import AleaSlider from './AleaSlider.vue'
  import { filesGetSignedUrl } from '../../../api/index.js'

  const props = defineProps({
    modelValue: {
      type: Object
    },
    initialValues: {
      type: Object,
      required: false
    },
    fields: {
      type: Array,
      required: true
    },
    submitLabel: {
      type: String,
      required: false,
      default: 'submit'
    },
    submitProgress: {
      type: Boolean,
      required: false,
      default: false
    },
    showSubmit: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    layout: {
      type: String,
      default: 'form',
      validator: (v) =>
        [
          'kv', // key-value: label left, input right
          'form' // label top, input below
        ].includes(v)
    },
    mode: {
      type: String,
      default: 'edit',
      validator: (v) => ['edit', 'view'].includes(v)
    },
    fileUploadOnBehalfUserId: {
      type: String
    },
    restrictWidth: {
      type: Boolean,
      default: true
    },
    submitDisabled: {
      type: Boolean,
      default: false
    }
  })

  const emit = defineEmits(['submit', 'cancel', 'update:modelValue'])

  configure({
    validateOnBlur: true,
    validateOnChange: false,
    validateOnInput: false,
    validateOnModelUpdate: false
  })

  const validations = computed(() =>
    props.fields.reduce((acc, field) => {
      if (field.validation) {
        acc[field.id] = field.validation
      } else {
        acc[field.id] = z.any() // do not validate
      }
      return acc
    }, {})
  )

  const validationSchema = computed(() => toTypedSchema(z.object(validations.value)))

  const form = useForm({
    validationSchema
    // ...(props.initialValues ? {initialValues: props.initialValues} : {}),
  })

  const onSubmit = form.handleSubmit((values) => {
    emit('submit', values)
  })

  function onFieldUpdate(fieldId, value) {
    emit('update:modelValue', { ...form.values })
  }

  function setFormValues(values, validate = false) {
    if (!values) return

    // console.log("setFormValues", values, validate)

    if (validate) {
      form.setValues(values) // triggers validation
    } else {
      form.values = { ...values } // does not trigger validation
    }
  }

  async function onClickDownload({ field, fileId }) {
    console.log('onClickDownload', field, fileId)
    const res = await filesGetSignedUrl(
      {
        kind: field.file.kind,
        action: 'read',
        fileId,
        userId: props.fileUploadOnBehalfUserId
      },
      { notifySuccess: false }
    )
    window.open(res.response?.data?.url, '_blank')
  }

  function getOptionLabel(field, option) {
    return field.options.find((o) => o.value === option)?.label || option || ''
  }

  function init() {
    if (props.mode === 'edit') {
      setFormValues(props.modelValue, true)
    } else {
      setFormValues(props.modelValue, false)
    }
  }

  watch(
    () => props.mode,
    (mode) => {
      init()
    },
    { immediate: true }
  )

  defineExpose({
    onSubmit,
    setFormValues,
    values: form.values
  })
</script>

<template>
  <form
    class="flex flex-col gap-4"
    :class="[{ 'max-w-md md:min-w-96': restrictWidth }]"
    @submit="onSubmit"
  >
    <table>
      <!-- fields -->
      <tr
        :class="[{ 'border-b last:border-none border-neutral-100': layout === 'kv' }]"
        v-for="field in fields"
        :key="field.id"
      >
        <FormField v-slot="{ componentField }" :name="field.id">
          <td class="py-2 pr-4" v-if="layout === 'kv'">
            <AleaLabel :label="field.label" v-if="field.ui !== 'separator'" />
          </td>

          <!-- mode edit: editors -->
          <td class="py-3.5 pr-1">
            <!-- input -->
            <AleaInput
              v-bind="componentField"
              :label="layout === 'form' ? field.label : false"
              :sublabel="layout === 'form' ? field.sublabel : false"
              :placeholder="field.placeholder"
              :caption="field.caption"
              :type="field.type || 'text'"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'input'"
            />

            <!-- textarea -->
            <AleaTextarea
              v-bind="{ ...componentField, ...field.textarea }"
              :label="layout === 'form' ? field.label : false"
              :placeholder="field.placeholder"
              :caption="field.caption"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'textarea'"
            />

            <!-- select -->
            <AleaSelect
              v-bind="{ ...componentField, ...field.select }"
              :options="field.options"
              :label="layout === 'form' ? field.label : false"
              :sublabel="layout === 'form' ? field.sublabel : false"
              :placeholder="field.placeholder"
              :caption="field.caption"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'select'"
            />

            <!-- multiselect -->
            <AleaMultiselect
              v-bind="{ ...componentField, ...field.multiselect }"
              :options="field.options"
              :label="layout === 'form' ? field.label : false"
              :placeholder="field.placeholder"
              :caption="field.caption"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'multiselect'"
            />

            <!-- switch -->
            <AleaSwitch
              v-bind="componentField"
              :label="layout === 'form' ? field.label : false"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'switch'"
            />

            <!-- datepicker -->
            <AleaDatepicker
              v-bind="{ ...componentField, ...field.datepicker }"
              :label="layout === 'form' ? field.label : false"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'datepicker'"
            />

            <!-- radio -->
            <AleaRadioGroup
              v-bind="componentField"
              :options="field.options"
              :label="layout === 'form' ? field.label : false"
              :sublabel="layout === 'form' ? field.sublabel : false"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'radio'"
            />

            <!-- checkbox -->
            <AleaCheckbox
              v-bind="componentField"
              :options="field.options"
              :label="layout === 'form' ? field.label : false"
              :subLabel="layout === 'form' ? field.subLabel : false"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'checkbox'"
            />

            <!-- toggle group -->
            <AleaToggleGroup
              v-bind="componentField"
              :options="field.options"
              :label="layout === 'form' ? field.label : false"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'toggleGroup'"
            />

            <!-- slider -->
            <AleaSlider
              v-bind="{ ...componentField, ...field.slider }"
              :label="layout === 'form' ? field.label : false"
              :caption="field.caption"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'slider'"
            />

            <!-- file -->
            <AleaFile
              v-bind="{ ...componentField, ...field.file }"
              :label="layout === 'form' ? field.label : false"
              :caption="field.caption"
              :uploadOnBehalfUserId="fileUploadOnBehalfUserId"
              @update:modelValue="onFieldUpdate(field.id, $event)"
              v-if="mode === 'edit' && field.ui === 'file'"
            />

            <!-- note -->
            <div class="font-normal text-sm text-neutral-500" v-if="field.ui === 'note'">
              <p>{{ field.note }}</p>
            </div>

            <!-- separator -->
            <div class="pt-4" v-if="field.ui === 'separator'">
              <span class="text-neutral-500">{{ field.label }}</span>
            </div>

            <!-- value -->
            <div class="font-normal text-sm" v-if="mode === 'view'">
              <!-- multiselect -->
              <div
                class="flex flex-wrap flex-row justify-start gap-2"
                v-if="field.ui === 'multiselect'"
              >
                <span
                  v-for="option in modelValue[field.id]"
                  :key="option"
                  class="block font-normal text-sm bg-neutral-100 px-2.5 py-1 rounded-full"
                >
                  {{ getOptionLabel(field, option) }}
                </span>
              </div>

              <!-- select -->
              <span v-else-if="field.ui === 'select'">
                {{ getOptionLabel(field, modelValue[field.id]) }}
              </span>

              <!-- file -->
              <span v-else-if="field.ui === 'file'">
                <span
                  class="cursor-pointer underline"
                  @click="onClickDownload({ field, fileId: modelValue[field.id] })"
                  v-if="modelValue[field.id]"
                >
                  download
                </span>
              </span>
              <span v-else>{{ modelValue[field.id] }}</span>
            </div>
          </td>
        </FormField>
      </tr>
    </table>

    <!-- buttons -->
    <div
      class="flex flex-row items-center gap-2"
      v-if="mode === 'edit' && (showSubmit || showCancel)"
    >
      <!-- cancel -->
      <AleaButton
        class="w-1/2"
        label="cancel"
        variant="outline"
        type="button"
        @click="emit('cancel')"
        v-if="showCancel"
      />

      <!-- submit -->
      <AleaButton
        :class="[{ 'w-full': !showCancel }, { 'w-1/2': showCancel }]"
        :label="submitLabel"
        :progress="submitProgress"
        :disabled="submitDisabled"
        type="submit"
        v-if="showSubmit"
      />
    </div>
  </form>
</template>
